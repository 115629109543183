"use client"

import { useGetAllSubTraining } from "@/actions/sub-training"
import { useGetTrainingByCodeCombobox } from "@/actions/training"
import {
  useGetAllTypeTrainingComboBox,
  useGetTypeAllTrainingByNameComboBox,
} from "@/actions/type-training"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { formatDotString } from "@/lib/string"
import { cn } from "@/lib/tailwind-utils"
import { Check } from "lucide-react"
import { useRouter } from "next/navigation"
import { useState } from "react"
import { AiOutlineLoading3Quarters } from "react-icons/ai"

const PelatihanCombobox = ({
  name,
  className,
  placeholder,
  searchPlaceholder,
  isLoading = false,
  isSuccess = false,
  isError = false,
  value,
  setValue,
  choosedValue,
  handleChoosedValue,
  datas = [],
}) => {
  const [open, setOpen] = useState(false)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="reset"
          role="combobox"
          aria-expanded={open}
          className={cn(
            "w-full  lg:col-span-2 p-4 border-b lg:border-b-0 col-span-7 lg:border-r flex h-full justify-between rounded-none  text-txt14_20",
            className,
          )}>
          {value
            ? formatDotString(
                datas.find((data) => data.value === value)?.label,
                50,
              )
            : placeholder}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height] p-0">
        <Command>
          <CommandInput placeholder={searchPlaceholder} />
          {isLoading && (
            <CommandList>
              <CommandGroup>
                {Array.from({ length: 1 }).map((_, index) => (
                  <CommandItem key={index + 1}>
                    <AiOutlineLoading3Quarters className="mr-2 h-4 w-4 animate-spin" />{" "}
                    Sedang memuat data...
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          )}
          {isSuccess && (
            <CommandList>
              <CommandEmpty>
                {name ? `${name} tidak ditemukan.` : "Data tidak ditemukan."}
              </CommandEmpty>
              <CommandGroup>
                {datas.map((data) => {
                  return (
                    <CommandItem
                      key={data.value}
                      value={data.value}
                      onSelect={(currentValue) => {
                        setValue(currentValue === value ? "" : currentValue)
                        // handleChoose(currentValue === value ? "" : currentValue)
                        handleChoosedValue(
                          choosedValue?.label === data?.label ? null : data,
                        )
                        setOpen(false)
                      }}>
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          value === data.value ? "opacity-100" : "opacity-0",
                        )}
                      />
                      {data.label}
                    </CommandItem>
                  )
                })}
              </CommandGroup>
            </CommandList>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  )
}

export default function Header() {
  const router = useRouter()
  const [choosedSubtraining, setChoosedSubTraining] = useState({})
  const [subTrainingSearch, setSubTrainingSearch] = useState("")
  const {
    data: allSubTraining,
    isFetching: isLoadingGetAllSubTraining,
    isSuccess: isSuccessGetAllSubTraining,
  } = useGetAllSubTraining()

  const handleChooseSubTraining = (value) => {
    if (!value) {
      setChoosedSubTraining({})
    }

    setChoosedSubTraining(value)
  }
  const {
    data: allTypeTraining,
    isFetching: isLoadingGetAllTypeTraining,
    isSuccess: isSuccessGetAllTypeTraining,
    // isError: isErrorGetAllTypeTraining,
  } = useGetAllTypeTrainingComboBox()

  const [trainingTypeSearch, setTrainingTypeSearch] = useState("")
  const [trainingTypeId, setTrainingTypeId] = useState("")

  const {
    data: allTrainingByType,
    isFetching: isLoadingGetAllTrainingByType,
    isSuccess: isSuccessGetAllTrainingByType,
    // isError: isErrorGetAllTrainingByType,
  } = useGetTypeAllTrainingByNameComboBox(trainingTypeId?.id)

  const [trainingCode, setTrainingCode] = useState("")
  const [trainingCodeSearch, setTrainingCodeSearch] = useState("")

  const {
    data: allSubTrainingByTrainingCode,
    isFetching: isLoadingGetAllSubTrainingByTrainingCode,
    isSuccess: isSuccessGetAllSubTrainingByTrainingCode,
    // isError: isErrorGetAllSubTrainingByTrainingCode,
  } = useGetTrainingByCodeCombobox(trainingCode.code)

  const [subTrainingCode, setSubTrainingCode] = useState("")
  const [subTrainingCodeSearch, setSubTrainingCodeSearch] = useState("")

  const handleChooseTypeTraining = (value) => {
    if (!value) {
      setTrainingCodeSearch("")
      setSubTrainingCodeSearch("")
    }
    if (value?.label !== trainingTypeId?.label) {
      setTrainingCodeSearch("")
      setSubTrainingCodeSearch("")
    }
    setTrainingTypeId(value)
  }

  const handleChooseTrainingCode = (value) => {
    if (!value) {
      setSubTrainingCodeSearch("")
    }
    if (value?.label !== trainingTypeId?.label) {
      setSubTrainingCodeSearch("")
    }
    setTrainingCode(value)
  }

  const handleChooseSubTrainingCode = (value) => {
    setSubTrainingCode(value)
  }

  const handleSearchPelatihan = () => {
    let url = "/pelatihan"

    // if (trainingTypeId?.id) {
    //   url += `?type=${trainingTypeId?.id}`
    // }

    // if (trainingCode?.id) {
    //   if (trainingTypeId?.id) {
    //     url += `&training-code=${trainingCode?.code}`
    //   } else {
    //     url += `?training-code=${trainingCode?.code}`
    //   }
    // }

    // if (subTrainingCode?.id) {
    //   if (subTrainingCode?.id) {
    //     url += `&sub-training-code=${subTrainingCode?.code}`
    //   } else {
    //     url += `?subtraining-code=${subTrainingCode?.code}`
    //   }
    //   return router.push(
    //     `/pelatihan/${String(subTrainingCode?.code).toLowerCase()}`,
    //   )
    // }

    if (choosedSubtraining?.id) {
      return router.push(
        `/pelatihan/${String(choosedSubtraining?.subTrainingCode).toLowerCase()}`,
      )
    }

    return router.push(url)
  }

  // console.log("choosedL", choosedSubtraining)

  return (
    <section className="bg-gray-50">
      <div className="mx-auto max-w-screen-xl px-4 lg:py-32 py-14  flex gap-y-6 lg:gap-y-12 flex-col justify-center  items-center">
        <div className="mx-auto max-w-2xl text-center">
          <Badge
            variant="outline"
            className={"bg-color-4 text-white px-5 py-1"}>
            Silangit oleh BPPP Tegal
          </Badge>
          <div className="pt-6">
            <h1 className="text-3xl font-extrabold sm:text-5xl">
              Dapatkan pelatihan sesuai
              <strong className="font-extrabold text-color-4 pl-1 lg:pl-0 sm:block">
                dengan Skill Anda
              </strong>
            </h1>
            <p className="mt-4 sm:text-xl/relaxed lg:text-txt16_24">
              Tingkatkan keterampilan maritim Anda agar sesuai dengan kebutuhan
              dan tren terkini sehinga relevan untuk tetap kompetitif di
              industri pelayaran, kelautan, dan perikanan.
            </p>
          </div>
        </div>
        <div className=" w-full  max-w-2xl   grid lg:grid-cols-7 grid-cols-4    items-center    overflow-hidden border rounded-[10px]  ">
          {/* <PelatihanCombobox
            name="Unit Diklat"
            placeholder={"Pilih Unit Diklat"}
            searchPlaceholder="Cari Unit Diklat..."
            isLoading={isLoadingGetAllTypeTraining}
            isSuccess={isSuccessGetAllTypeTraining}
            value={trainingTypeSearch}
            setValue={setTrainingTypeSearch}
            choosedValue={trainingTypeId}
            handleChoosedValue={handleChooseTypeTraining}
            datas={allTypeTraining}
          />
          <PelatihanCombobox
            name="Diklat"
            placeholder={"Pilih Diklat"}
            searchPlaceholder="Cari Diklat..."
            isLoading={isLoadingGetAllTrainingByType}
            isSuccess={isSuccessGetAllTrainingByType}
            value={trainingCodeSearch}
            setValue={setTrainingCodeSearch}
            choosedValue={trainingCode}
            handleChoosedValue={handleChooseTrainingCode}
            datas={allTrainingByType}
          />
          <PelatihanCombobox
            name="Pelatihan"
            placeholder={"Pilih Pelatihan"}
            searchPlaceholder="Cari Pelatihan..."
            isLoading={isLoadingGetAllSubTrainingByTrainingCode}
            isSuccess={isSuccessGetAllSubTrainingByTrainingCode}
            value={subTrainingCodeSearch}
            setValue={setSubTrainingCodeSearch}
            choosedValue={subTrainingCode}
            handleChoosedValue={handleChooseSubTrainingCode}
            datas={allSubTrainingByTrainingCode}
          /> */}

          <PelatihanCombobox
            className="lg:col-span-5 col-span-7"
            name="Pelatihan"
            placeholder={"Pilih Pelatihan"}
            searchPlaceholder="Cari Pelatihan..."
            isLoading={isLoadingGetAllSubTraining}
            isSuccess={isSuccessGetAllSubTraining}
            value={subTrainingSearch}
            setValue={setSubTrainingSearch}
            choosedValue={choosedSubtraining}
            handleChoosedValue={handleChooseSubTraining}
            datas={allSubTraining}
          />
          <div className="  lg:col-span-2 col-span-7 lg:p-2 p-2">
            <Button
              type="button"
              onClick={handleSearchPelatihan}
              className="rounded-[10px] w-full  bg-color-4 text-white hover:bg-color-4/90 h-[40px]">
              Cari Pelatihan
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}
