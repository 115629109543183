import { useQuery } from "@tanstack/react-query"
import { apiInstance } from "./api-instance"

export const TypeTraining = {
  async getAllType() {
    return apiInstance.get(
      `/type-training?APIkey=${process.env.NEXT_PUBLIC_API_KEY}`,
    )
  },
  async getAllTrainingByName({ id_type_training }) {
    return apiInstance.post(
      `/type-training/get-type-search-name-sub?APIkey=${process.env.NEXT_PUBLIC_API_KEY}`,
      {
        id_type_training,
        sub_name: "a",
      },
    )
  },
}

export const useGetTypeAllTrainingByName = (
  id_type_training,
  queryOpt = {},
) => {
  return useQuery({
    queryKey: ["get_all_type_training_name", id_type_training],
    queryFn: async () => {
      if (id_type_training === "semua") {
        const response = await apiInstance.get(
          `/training?APIkey=${process.env.NEXT_PUBLIC_API_KEY}`,
        )
        const datas = response?.data?.data
        let trainings
        if (datas?.length > 0) {
          trainings = datas?.map((training) => {
            return {
              id: training?.id,
              name: String(training?.name).toUpperCase(),
              code: training?.code,
              desc: training?.description,
            }
          })
        }

        return datas.length > 0 ? trainings : []
      }

      const response = await TypeTraining.getAllTrainingByName({
        id_type_training,
      })
      const datas = response.data.data.type_training.training

      let filteredDatas
      if (datas?.length > 0) {
        filteredDatas = datas?.map((training) => {
          return {
            id: training?.id,
            name: String(training?.name).toUpperCase(),
            code: training?.code,
            desc: training?.description,
          }
        })
      }
      return datas?.length > 0 ? filteredDatas : []
    },
    enabled: !!id_type_training,
    ...queryOpt,
  })
}

export const useGetAllTypeTraining = (queryOpt = {}) => {
  return useQuery({
    queryKey: ["get_all_type_training"],
    queryFn: async () => {
      const response = await TypeTraining.getAllType()

      const datas = response.data.data
      let filteredDatas

      if (datas?.length > 0) {
        filteredDatas = datas?.map((typeTraining) => {
          return {
            id: typeTraining?.id,
            name: String(typeTraining?.name).toUpperCase(),
            description: String(typeTraining?.description).toUpperCase(),
          }
        })
      }
      return datas?.length > 0 ? filteredDatas : []
    },
    ...queryOpt,
  })
}

export const useGetAllTypeTrainingComboBox = (queryOpt = {}) => {
  return useQuery({
    queryKey: ["get_all_type_training_combobox"],
    queryFn: async () => {
      const response = await TypeTraining.getAllType()
      const datas = response.data.data
      let filteredDatas

      if (datas?.length > 0) {
        filteredDatas = datas?.map((typeTraining) => {
          return {
            id: typeTraining?.id,
            label: String(typeTraining?.name).toUpperCase(),
            value: String(typeTraining?.name).toUpperCase(),
            desc: typeTraining?.description,
          }
        })
      }
      return datas?.length > 0 ? filteredDatas : []
    },
    ...queryOpt,
  })
}

export const useGetTypeAllTrainingByNameComboBox = (
  id_type_training,
  queryOpt = {},
) => {
  return useQuery({
    queryKey: ["get_all_type_training_name_combobox", id_type_training],
    queryFn: async () => {
      const response = await TypeTraining.getAllTrainingByName({
        id_type_training,
      })
      const datas = response.data.data.type_training.training

      let filteredDatas
      if (datas?.length > 0) {
        filteredDatas = datas?.map((typeTraining) => {
          return {
            id: typeTraining?.id,
            label: String(typeTraining?.name).toUpperCase(),
            value: String(typeTraining?.name).toUpperCase(),
            code: typeTraining?.code,
            desc: typeTraining?.description,
          }
        })
      }
      return datas?.length > 0 ? filteredDatas : []
    },
    enabled: !!id_type_training,
    ...queryOpt,
  })
}
