import { useQuery } from "@tanstack/react-query"
import { apiInstance } from "./api-instance"

export const getSubTrainingByCode = async (sub_training_code) => {
  const response = await apiInstance.get(
    `/sub-training/show-by-code/${sub_training_code}?APIkey=${process.env.NEXT_PUBLIC_API_KEY}`,
  )
  return response
}

export const SubTraining = {
  async getByCode(code) {
    return apiInstance.get(
      `/type-training?APIkey=${process.env.NEXT_PUBLIC_API_KEY}`,
    )
  },
  async getAllTrainingByName({ id_type_training }) {
    return apiInstance.post(
      `/type-training/get-type-search-name-sub?APIkey=${process.env.NEXT_PUBLIC_API_KEY}`,
      {
        id_type_training,
        sub_name: "a",
      },
    )
  },
}

// get sub_training by sub_training_code
export const useGetSubTrainingByCode = (
  sub_training_code = "",
  querySetting = {},
) => {
  return useQuery({
    queryKey: ["get_sub_training_by_code", sub_training_code],
    queryFn: async () => {
      const response = await apiInstance.get(
        `/sub-training/show-by-code/${sub_training_code}?APIkey=${process.env.NEXT_PUBLIC_API_KEY}`,
      )
      const sub_training = response?.data?.data

      return sub_training
    },
    enabled: !!sub_training_code,
    ...querySetting,
  })
}

// get sub_training by sub_training_code
export const useGetAllSubTraining = (querySetting = {}) => {
  return useQuery({
    queryKey: ["get_all_sub_training"],
    queryFn: async () => {
      const response = await apiInstance.get(
        `/sub-training?APIkey=${process.env.NEXT_PUBLIC_API_KEY}`,
      )
      const sub_trainings = response?.data?.data
      let filteredSubTrainings

      if (sub_trainings.length > 0) {
        filteredSubTrainings = sub_trainings.map((sub, index) => {
          return {
            id: index + 1,
            label: String(sub?.name).toUpperCase(),
            value: String(sub?.name).toUpperCase(),
            subTrainingCode: sub?.code,
            trainingCode: sub?.training?.code,
            trainingName: sub?.training?.name,
            typeTraining: sub?.training?.type_training?.name,
          }
        })
      }

      return sub_trainings.length > 0 ? filteredSubTrainings : []
    },
    ...querySetting,
  })
}
