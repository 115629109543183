export const formatRupiah = (number) => {
  let numbers = Number(number)
  return numbers?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })
}

export const formatDotString = (string, longOfString) => {
  return string.length < longOfString
    ? string
    : string.substring(0, longOfString) + "..."
}

export const capitalizeSentence = (sentence) => {
  const words = sentence.split(" ")

  for (let i = 0; i < words.length; i++) {
    words[i] =
      words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase()
  }

  return words.join(" ")
}
