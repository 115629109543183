import { useQuery } from "@tanstack/react-query"
import { apiInstance } from "./api-instance"

export const useGetTrainingByCode = (
  typeTrainingId,
  training_code,
  querySetting = {},
) => {
  return useQuery({
    queryKey: ["get_training_by_code", training_code, typeTrainingId],
    queryFn: async () => {
      if (training_code === "semua") {
        if (typeTrainingId !== "semua") {
          const response = await apiInstance.get(
            `/type-training/show/${typeTrainingId}?APIkey=${process.env.NEXT_PUBLIC_API_KEY}`,
          )
          const datas = response?.data?.data?.training
          let filteredSubtrainingDatas

          if (response?.data?.data.id) {
            filteredSubtrainingDatas = datas.flatMap(
              (training) => training.sub_training,
            )
          }

          return response?.data?.data?.id ? filteredSubtrainingDatas : []
        }

        const response = await apiInstance.get(
          `/sub-training?APIkey=${process.env.NEXT_PUBLIC_API_KEY}`,
        )
        const sub_training = response?.data?.data

        return sub_training
      }

      const response = await apiInstance.get(
        `/training/show-by-code/${training_code}?APIkey=${process.env.NEXT_PUBLIC_API_KEY}`,
      )
      const subTrainingDatas = response?.data?.data?.sub_training

      return subTrainingDatas?.length > 0 ? subTrainingDatas : []
    },
    enabled: !!training_code,
    ...querySetting,
  })
}

export const useGetTrainingByCodeCombobox = (
  training_code,
  querySetting = {},
) => {
  return useQuery({
    queryKey: ["get_training_by_code_combobox", training_code],
    queryFn: async () => {
      const response = await apiInstance.get(
        `/training/show-by-code/${training_code}?APIkey=${process.env.NEXT_PUBLIC_API_KEY}`,
      )
      const subTrainingDatas = response?.data?.data?.sub_training
      let filteredSubtrainingDatas

      if (subTrainingDatas?.length > 0) {
        filteredSubtrainingDatas = subTrainingDatas?.map((subTraining) => {
          return {
            id: subTraining?.id,
            label: String(subTraining?.name).toUpperCase(),
            value: String(subTraining?.name).toUpperCase(),
            code: subTraining?.code,
            desc: subTraining?.description,
          }
        })
      }

      return subTrainingDatas?.length > 0 ? filteredSubtrainingDatas : []
    },
    enabled: !!training_code,
    ...querySetting,
  })
}
